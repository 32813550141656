import React from "react";
import { Link } from 'react-router-dom';



export class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: this.props.location.pathname,
            display: false,
        };

    }

    componentDidMount() {
        this.setState({ display: true });
    }

    onLinkClick = () => {
        this.setState({ display: false });
    }


    render() {
        return (
            <div className="row" style={{ paddingTop: '20px', display: this.state.display ? 'block' : 'none' }}>
                <div className="col-lg-2">&nbsp;</div>
                <div className="col-lg-10">
                    <h5>"{this.state.url}" findes ikke.</h5>
                    <p>Siden: {this.state.url} findes ikke på <Link onClick={this.onLinkClick.bind(this)} tag="Link" to="/">servicehunde-steder.dk</Link></p> </div>
                <div className="col-lg-2">   </div>
            </div>
        );
    }
}

