import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Loader } from '../Loader/Loader';
import SaveIcon from '@material-ui/icons/Save';
import './PageEdit.css';
import Fab from '@material-ui/core/Fab';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
//import Collapse from '@material-ui/core/Collapse';
//import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {
    Col, Row, Form,
    FormGroup, Label, Input,

} from 'reactstrap';
//import { Location } from '../Location/Locations';
export class PageEdit extends Component {
    static displayName = PageEdit.name;

    constructor(props) {
        super(props);
        this.state = {
            page: {},
            friendlyUrl: props.match.params.path,
            loading: true,
            changed: false,
            saving: false,
            snackBarOpen: false,
            vertical: 'top',
            horizontal: 'center',
        }
        this.handleChange = this.handleChange.bind(this)
        this.savePage.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleChange(content) {
        console.log(content); //Get Content Inside Editor
        this.setState({ page: { ...this.state.page, content: content } });
    }

    handleClose = () => {
        this.setState({ ...this.state, snackBarOpen: false });
    };

    updateContent(value) {
        this.setState({ changes: true })
    }

    showSnackBar = (message) => {
        this.setState({ ...this.state, snackBarOpen: true });
    }


    //componentWillReceiveProps(nextProps) {
    //    // check current props and nextPros of dealLoaded flag. If dealLoaded was false, and is true, which means the data is fetched, then we should reset isFlushed
    //    this.populatePageData(nextProps.match.params.path);

    //    if (!this.props.loading && nextProps.loading) {
    //        this.setState({
    //            loading: true
    //        });
    //    }

    //}

    handleEditorChange = (content, editor) => {
        console.log('Content was updated:', content);
    }

    handleTextboxChange = (event) => {
        this.setState({ page: { ...this.state.page, [event.target.id]: event.target.value } })

    }

    componentDidMount() {
        this.populatePageData(this.state.friendlyUrl);
    }

    savePage = () => {
        this.setState({ saving: true });
        this.saveThePage(this.state.page);

    }


    componentWillUnmount() {

    }

    renderPageEditor(page) {

        return (
            <Form className="form">
                <Col>
                    <FormGroup>
                        <Label>Overskrift</Label>
                        <Input
                            type="text"
                            name="header"
                            id="title"
                            required
                            value={this.state.page.title}
                            onChange={this.handleTextboxChange.bind(this)}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Friendly Url</Label>
                        <Input
                            type="text"
                            name="header"
                            id="friendlyUrl"
                            required
                            value={this.state.page.friendlyUrl}
                            onChange={this.handleTextboxChange.bind(this)}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="examplePassword">Indhold</Label>


                        <SunEditor
                            setContents={page.content}
                            onChange={this.handleChange}
                            showToolbar={true}
                            setOptions={{
                                height: 'calc(100vh - 350px)',
                                mode: "classic",
                                katex: "window.katex",
                                buttonList: [[
                                    //{
                                    //    name: 'custom_container',
                                    //    dataDisplay: 'container',
                                    //    title: 'custom_container',
                                    //    buttonClass: '',
                                    //    innerHTML: '<svg viewBox="0 0 24 24" style="width:24px;height:24px;"><path fill="currentColor" d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>'
                                    //},
                                    "undo",
                                    "redo",
                                    "font",
                                    "fontSize",
                                    "formatBlock",
                                    "paragraphStyle",
                                    "blockquote",
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "subscript",
                                    "superscript",
                                    "fontColor",
                                    "hiliteColor",
                                    "textStyle",
                                    "removeFormat",
                                    "outdent",
                                    "indent",
                                    "align",
                                    "horizontalRule",
                                    "list",
                                    "lineHeight",
                                    "table",
                                    "link",
                                    "image",
                                    "video",
                                    "audio",
                                    "math",
                                    "fullScreen",
                                    "showBlocks",
                                    "codeView",
                                    "preview",

                                    //"save",

                                ]
                                ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                                // Other option
                                //callBackSave: { savePage() } //this.postData(),
                            }}
                        />
                    </FormGroup>
                </Col>

            </Form>

        );
    }

    render() {
        let contents = this.state.loading
            ? <Loader />
            : this.renderPageEditor(this.state.page);

        return (
            <Row>
                <Snackbar 
                    autoHideDuration={6000} 
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    key={`${this.state.vertical},${this.state.horizontal}`}
                    open={this.state.snackBarOpen}
                    onClose={this.handleClose}
                >
                    <Alert
                        severity="success"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    this.setState({snackBarOpen: false});
                                }}

                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {"Siden: \"" + this.state.page.title + "\" er gemt"}</Alert>
                </Snackbar>
                <Col lg="2">
                    <div style={{ display: this.state.saving ? 'block' : 'none' }}><Loader title="Gemmer" /></div></Col>
                <Col lg="8" xs="12">
                    <Helmet>
                        <title> {"Ret side: " + this.state.page.title + " - servicehunde-steder.dk"}</title>
                    </Helmet>
                    <h4>Rediger: {this.state.page.title}
                        <Fab
                            className="float-rigth "
                            style={{ position: 'fixed', top: '60px', right: '10%', float: 'right', zIndex: '99999' }}
                            color="primary"
                            size="small"
                            id="search"
                            onClick={this.savePage}
                            title={"Gem siden: '" + this.state.page.title + "'"}>
                            <SaveIcon />
                        </Fab></h4>
                    {contents}

                </Col>
                <Col lg="2"></Col>
            </Row>
        );
    }

    async postData() {
        const data = this.state.page;
        await fetch('api/Page/Update    ', {
            method: 'post',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            this.setState({ saving: false });
            return response.json();
        }).then(function (responseData) {
            //Do your logic
            this.setState({ saving: false });
            console.log(responseData);
        });
        this.setState({ saving: false });

    }

    async saveThePage(page) {
        const response = await fetch('api/Page/Update    ', {
            method: 'post',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(page)
        })
        const data = await response.json();
        if (data >= 1)
            this.showSnackBar();

        this.setState({ saving: false });
    }


    async populatePageData(page) {
        const response = await fetch('/api/Page/GetByFriendlyUrl/' + page);
        const data = await response.json();
        this.setState({ page: data, loading: false });
    }
}
