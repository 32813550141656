import React, { Component, Fragment } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import PersonIcon from '@material-ui/icons/Person';
import './Login.css'
export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        userName = userName.substring(0, userName.indexOf("@"));
        return (<Fragment>

            <NavLink tag={Link} className="text-dark" to={logoutPath} title="Log af" style={{ width: 60 }}>
                <ListItemIcon style={{ marginLeft: '5px', float: 'left'}}>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                </ListItemIcon>
            </NavLink>

            <NavLink tag={Link} className="text-dark" to={profilePath}>Velkommen {userName}</NavLink>



        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            {/*   <NavItem>
                <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
            </NavItem> */}
            <NavLink tag={Link} className="text-dark" to={loginPath}>
                <span id="tvop-menu-button" >
                    <ListItemIcon style={{ marginLeft: '5px' }}>
                        <FontAwesomeIcon icon={faSignInAlt} />
                    </ListItemIcon> Login
                    </span>
            </NavLink>


        </Fragment>);
    }
}
