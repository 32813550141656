import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import './AddPlaceModal.css';
import MenuItem from '@material-ui/core/MenuItem';
import { Col, Row } from 'reactstrap';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';



const place = {
    id: 0,
    Category: 0,
    Brand: 0,
    Name1: '',
    Name2: '',
    Address1: '',
    Address2: '',
    Zip: '',
    City: '',
    Phone: '',
    Email: '',
    Geotag: '',
    GooglePlusCode: '',
    HusNummer: '',
    WebSite: '',
    Longitude: null,
    Latitude: null,
    Description: ' ',
    Status: 0,
    Created: null,
    Updated: null,
    CreatedBy: 0,
    UpdatedBy: 0,
}



//const useStyles = makeStyles((theme) => ({
//    formControl: {
//        margin: theme.spacing(1),
//        minWidth: 120,
//    },
//    selectEmpty: {
//        marginTop: theme.spacing(2),
//    },
//}));



export class AddPlaceModal extends Component {
    static displayName = AddPlaceModal.name;
    constructor(props) {



        super(props);
        this.textFieldOnChange = this.textFieldOnChange.bind(this);
        this.selectCategoryOnChange = this.selectCategoryOnChange.bind(this);
        this.selectStatusOnChange = this.selectStatusOnChange.bind(this);
        this.selectBrandOnChange = this.selectBrandOnChange.bind(this);
        this.state = {
            categories: [],
            isOpen: this.props.handleOpen,
            modalData: this.props.modalData,
            place: place,
            loading: true,
        };
    }

    handleEntered = (ev) => {
    }

    handleClose() {
        this.setState({
            place: place
        })
        this.props.handleCancel();
    }

    handleSubmit() {
        this.postData();
        this.props.handleSubmit();
    }

    componentDidUpdate() {


    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.place) {
            this.setState({
                ...this.setState,
                place: nextProps.place
            });
        }
    }

    componentDidMount(props) {
        this.populateCategoryData();
        this.populateBrands();
    }

    textFieldOnChange(obj) {
        this.setState({
            place: {
                ...this.state.place,
                [obj.target.id]: obj.target.value
            }
        });

    }

    selectBrandOnChange(obj) {
        this.setState({
            place: {
                ...this.state.place,
                Brand: parseInt(obj.target.value)
            }
        });

    }

    selectStatusOnChange(obj) {
        this.setState({
            place: {
                ...this.state.place,
                Status: parseInt(obj.target.value)
            }
        });

    }

    selectCategoryOnChange(obj) {
        this.setState({
            place: {
                ...this.state.place,
                Category: parseInt(obj.target.value)
            }
        });

    }


    loadDialog(categories, place) {
        return (<Dialog
            open={this.props.handleOpen}
            onClose={this.handleClose}
            onEntered={this.handleEntered}
            maxWidth="sm"
            disableBackdropClick={true}
            fullWidth={true}
            modaldata={this.state.modalData}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Tilføj: {place ? place.Name1 + ", " + place.Address1 + ", " + place.Zip + " " + place.City : ''}</DialogTitle>
            <DialogContent>
                <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit.bind(this)}
                    onError={errors => console.log(errors)}
                >
                    <Row>
                        <Col lg="12">
                            <TextValidator
                                label="Navn 1"
                                name="Name1"
                                id="Name1"
                                onChange={this.textFieldOnChange}
                                value={this.state.place.Name1}
                                validators={['required']}
                                errorMessages={['Navn 1 skal udfyldes']}
                            />

                            <TextField
                                width={1}
                                id="Name2"
                                value={this.state.place.Name2}
                                style={{ width: '100%' }}
                                label="Evt. kontaktperson"
                                onChange={this.textFieldOnChange}
                                type="text"
                                fullWidth />
                            <Row>
                                <Col sm="6">
                                    <TextValidator
                                        id="Address1"
                                        style={{ width: '100%' }}
                                        value={this.state.place.Address1}
                                        label="Gadenavn"
                                        onChange={this.textFieldOnChange}
                                        validators={['required']}
                                        errorMessages={['Adresse 1 skal udfyldes']}
                                        type="text"
                                        fullWidth />
                                </Col>
                                <Col sm="6">
                                    <TextValidator
                                        width={1}
                                        id="HusNummer"
                                        value={this.state.place.HusNummer}
                                        style={{ width: '100%' }}
                                        label="Hus nummer"
                                        validators={['required']}
                                        errorMessages={['Husnummer skal udfyldes']}
                                        onChange={this.textFieldOnChange}
                                        fullWidth />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">

                                    <TextField
                                        width={1}
                                        id="Address2"
                                        value={this.state.place.Address2}
                                        style={{ width: '100%' }}
                                        label="Stednavn"
                                        onChange={this.textFieldOnChange}
                                        fullWidth />
                                </Col>
                                <Col sm="6">
                                    <Row>
                                        <Col sm="3">
                                            <TextValidator
                                                id="Zip"
                                                value={this.state.place.Zip}
                                                label="Postnummer"
                                                onChange={this.textFieldOnChange}
                                                validators={['required']}
                                                errorMessages={['Postnr. skal udfyldes']}

                                                fullWidth />
                                        </Col>
                                        <Col sm="9">
                                            <TextValidator
                                                width={100}
                                                id="City"
                                                value={this.state.place.City}
                                                label="By"
                                                onChange={this.textFieldOnChange}
                                                validators={['required']}
                                                errorMessages={['By skal udfyldes']}

                                                fullWidth />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="6">
                                    <TextField
                                        width={1}
                                        id="Phone"
                                        value={this.state.place.Phone}
                                        label="Telefon"
                                        onChange={this.textFieldOnChange}
                                    />
                                </Col>
                                <Col sm="6">
                                    <TextValidator
                                        width={1}
                                        id="Email"
                                        value={this.state.place.Email}
                                        label="Email"
                                        validators={['isEmail']}
                                        errorMessages={['Email forkert format']}
                                        onChange={this.textFieldOnChange}
                                        type="email"
                                    />
                                </Col>
                                <Col xs="6">
                                    <TextField
                                        width={1}
                                        id="WebSite"
                                        value={this.state.place.WebSite}
                                        label="Website"
                                        onChange={this.textFieldOnChange}
                                    />

                                </Col>
                                <Col xs="6">
                                    <SelectValidator
                                        label="Status"
                                        id="Status"
                                        value={this.state.place.Status}
                                        onChange={this.selectStatusOnChange}
                                        validators={['minNumber:1', 'maxNumber:3']}
                                        errorMessages={['Angiv status']}
                                    >
                                        <MenuItem disabled value={0}>
                                            <em>Angiv status</em>
                                        </MenuItem>
                                        <MenuItem value={1}>Oprettet</MenuItem>
                                        <MenuItem value={2}>Under godkendelse</MenuItem>
                                        <MenuItem value={3}>Godkendt</MenuItem>
                                    </SelectValidator>

                                </Col>
                            </Row>

                        </Col>


                        <Col xs="6">
                            <SelectValidator
                                label="Kategori"
                                id="Category"
                                value={this.state.place.Category}
                                onChange={this.selectCategoryOnChange}
                                validators={['minNumber:1', 'maxNumber:255']}
                                errorMessages={['Vælg en kategori']}
                            >
                                <MenuItem disabled key={0} value={0}>
                                    <em>Angiv kategori</em>
                                </MenuItem>
                                {categories.map(category =>
                                    <MenuItem key={category.id} value={category.id}>{category.categoryText}</MenuItem>

                                )}
                            </SelectValidator>

                        </Col>

                        <Col xs="6">
                            <SelectValidator
                                label="Brand"
                                id="Brand"
                                value={this.state.place.Brand}
                                onChange={this.selectBrandOnChange}
                                validators={['minNumber:1', 'maxNumber:255']}
                                errorMessages={['Vælg et brand']}
                            >
                                <MenuItem disabled key={0} value={0}>
                                    <em>Vælg et brand</em>
                                </MenuItem>
                                {this.state.brands.map(brand =>
                                    <MenuItem key={brand.id} value={brand.id}>{brand.brandName}</MenuItem>

                                )}
                            </SelectValidator>

                        </Col>

                        <Col xs="12">
                            <TextField
                                aria-label="Beskrivelse"
                                value={this.state.place.Description}
                                id="Description"
                                label="Beskrivelse"
                                width={2}
                                onChange={this.textFieldOnChange}
                                rows={1}
                                rowsMax={4}
                                placeholder="Beskrivelse"
                                multiline
                                fullWidth={true} />


                        </Col>
                    </Row>
                    <DialogActions>
                        <Button onClick={this.handleClose.bind(this)} color="default">
                            Annuller</Button>
                        <Button type="submit" color="primary">
                            Gem</Button>
                    </DialogActions>
                </ValidatorForm>




            </DialogContent>


        </Dialog>

        )
    }



    render() {
        let contents = this.state.loading
            ? <> </>
            : this.loadDialog(this.state.categories, this.state.place);


        return (
            <>
                {contents}
            </>

        )
    }


    async postData() {
        const data = this.state.place;
        await fetch('api/Location/Create', {
            method: 'post',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            return response.json(); //response.json() is resolving its promise. It waits for the body to load
        }).then(function (responseData) {
            //Do your logic
            console.log(responseData);
        });


    }

    async populateBrands() {
        const response = await fetch('/api/Brand/GetForList');
        const data = await response.json();


        this.setState({ brands: data, loading: false });

    }

    async populateCategoryData() {
        const response = await fetch('/api/Categori');
        const data = await response.json();



        this.setState({ categories: data });

    }

}
