import React, { Component } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import './Card.css';
import { Loader } from '../Loader/Loader';

export class BrandDetail extends Component {
    static displayName = BrandDetail.name;
    constructor(props) {
        super(props);
        this.state = {
            brand: this.props.brand,
            loading: false,
        };
    }



    renderBrandsDetails = (brand) => {
        const style = {
            fontSize: 18,
            color: 'white',
            backgroundColor: '#68529a',
            margin: '0 35px',
            padding: '1px 0 0',
        };
        return (
            <div style={{...style }}>
                <Col lg="2">
                </Col>
                <Col lg="10">
                    <h5 className="headera">
                        {brand.brandName}</h5>
                </Col>
                <div>
                    <Alert style={{ backgroundColor: '#ccbedf' }} >
                        <Row >
                            <Col lg="2">
                            </Col>
                            <Col lg="2">
                                <a href={brand.webSite} target="_blank" rel="noopener noreferrer">
                                    <img src={`data:image/jpeg;base64,${brand.logoBlob}`} rounded="true" responsive="true" style={{ height: '75px' }} title={"Vis " + brand.brandName} alt={brand.brandName} />
                                </a>

                            </Col>
                            <Col className="bg-Info" lg="6">

                                <p style={{ margin: '0 5px' }} dangerouslySetInnerHTML={{ __html: brand.description }} />
                            </Col>

                        </Row>
                    </Alert>
                </div>

            </div>
        );
    }


    render() {
        let contents = this.state.loading
            ? <Loader title="Henter data..." />
            : this.renderBrandsDetails(this.state.brand);

        return (
            <>{contents}
            </>

        )
    }

}