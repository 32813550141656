import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import CustomizedMenus, { AppMenu } from './AppMenu'
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import Hidden from '@material-ui/core/Hidden';
import './AppNav.css'
const useStylesTop = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },

}));

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },

    },
    menuTitle: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        color: '#FFF',
        '&: hover': {
            textDecoration: 'none !important',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '300px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '300px',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

//function ScrollTop(props) {
//    const { children, window } = props;
//    const classes = useStylesTop();
//    // Note that you normally won't need to set the window ref as useScrollTrigger
//    // will default to window.
//    // This is only being set here because the demo is in an iframe.
//    const trigger = useScrollTrigger({
//        target: window ? window() : undefined,
//        disableHysteresis: true,
//        threshold: 100,
//    });

//    const handleClick = (event) => {
//        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

//        if (anchor) {
//            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
//        }
//    };

//    return (
//        <Zoom in={trigger}>
//            <div onClick={handleClick} role="presentation" className={classes.root}>
//                {children}
//            </div>
//        </Zoom>
//    );
//}

//ScrollTop.propTypes = {
//    children: PropTypes.element.isRequired,
//    /**
//     * Injected by the documentation to work in an iframe.
//     * You won't need it on your project.
//     */
//    window: PropTypes.func,
//};

export default function PrimarySearchAppBar() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    //const isLoggedIn = sessionStorage.getItem("isLoggegIn");
    const [isLoggedIn, setIsLoggedIn] = React.useState(sessionStorage.getItem("isLoggedIn")      );

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleIsLoggedIn = () => {
        setIsLoggedIn(sessionStorage.getItem("isLoggedIn"));
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const HandleShow = (obj) => {
        let retval = "none";
        switch (obj) {
            case "login":
                isLoggedIn ? retval = 'none' : retval = 'block'; 
                break;
            default:
                isLoggedIn ? retval = 'block' : retval = 'none'; 
                break;
        }

        return retval;
    } 

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (<>

        <div className={classes.grow}>
            <CssBaseline />
            <AppBar color="primary" position="fixed">
                <Toolbar>

                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Vis menu"
                    >
                        <CustomizedMenus />
                    </IconButton>
                    <Link to="/" >
                        <Typography className={classes.Title} variant="h6" noWrap>
                            <img src="/images/logo/the-voice-of-pets-logo-white-border-200x200.png" width="40" /><Hidden only={['xs', 'sm']}>&nbsp;&nbsp; The Voice of Pets</Hidden>
                        </Typography>
                    </Link>
                    <div className={classes.grow} />

                    <div className={classes.sectionDesktop}>
                        <IconButton style={{ display: 'none' }} aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} color="secondary">
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton style={{ display: 'none' }} aria-label="show 17 new notifications" color="inherit">
                            <Badge badgeContent={17} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            
                            <NavLink tag="Link" tag={Link} to="/authentication/login" style={{ display: isLoggedIn ? 'none' : 'block' }} title="Log på servicehunde-steder.dk" >
                                <ExitToAppIcon style={{ color: '#ffffff', fontSize: '2.5rem' }} />
                            </NavLink>
                            <NavLink tag="Link" tag={Link} to="/authentication/profile" style={{ display: isLoggedIn ? 'block' : 'none'}} title="Min profil" >
                                <PersonIcon style={{ color: '#ffffff', fontSize: '2.5rem' }} /></NavLink>
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {/*{renderMobileMenu}
            //{renderMenu} */}
        </div>

    </>
    );
}
