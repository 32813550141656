import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { Helmet } from "react-helmet";
import { Loader } from '../Loader/Loader';


export class CategoryAdmin extends Component {

    static displayName = CategoryAdmin.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            columns: [
                { title: 'Id', field: 'id' },
                { title: 'Kategori tekst', field: 'categoryText' },
            ],
            categories: [],

        };
    }


    componentDidMount() {
        
        this.populateCategoryData();
    }
    loadCategoryTable(categories) {
        return (

            <MaterialTable
                title="Steder"
                columns={this.state.columns}
                data={categories}

                options={{
                    exportButton: false
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} af {count}'
                    },
                    toolbar: {
                        nRowsSelected: '{0} række(r) valgt'
                    },
                    header: {
                        actions: 'Actions'
                    },
                    body: {
                        emptyDataSourceMessage: 'Ingen data',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                {
                                    const data = this.state.categories;
                                    data.push(newData);
                                    this.setState({ categories: data }, () => resolve());

                                }
                                resolve()
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                {
                                    const data = this.state.categories;
                                    const index = data.indexOf(oldData);
                                    data[index] = newData;
                                    this.postData(newData);
                                    this.populateCategoryData(newData );
                                }
                                resolve()
                            }, 1000)
                        }),
                    
                }}
            />);
    }

    render() {
        let contents = this.state.loading
            ? <Loader />
            : this.loadCategoryTable(this.state.categories)
        return (<>
            <Helmet>
                <title>Kategori Administration - servicehunde-steder.dk</title>
            </Helmet>
            {contents}
        </>
        );

    }



    async postData(data) {
        
        await fetch('api/Categori/Create', {
            method: 'post',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            return response.json(); //response.json() is resolving its promise. It waits for the body to load
        }).then(function (responseData) {
            //Do your logic
            console.log(responseData);
        });


    }
    
    async populateCategoryData() {
        const response = await fetch('/api/Categori');
        const data = await response.json();
        this.setState({ categories: data, loading: false });
    }
}