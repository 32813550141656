import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { LoginMenu } from '../api-authorization/LoginMenu';
import MapIcon from '@material-ui/icons/Map';
import MenuIcon from '@material-ui/icons/Menu';
import PlaceIcon from '@material-ui/icons/Place';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { faDog } from '@fortawesome/free-solid-svg-icons'
import { faPaw } from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { faLandmark } from '@fortawesome/free-solid-svg-icons'
import { faGavel } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'

import './AppNav.css'

const StyledMenu = withStyles({
    paper: {
        border: '2px solid #68539b',
    },
})((props) => (
    <Menu
        elevation={3}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    //root: {
    //    '&:focus': {
    //        backgroundColor: theme.palette.primary.main,
    //        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //            color: theme.palette.common.white,
    //        },
    //    },
    //},
}))(MenuItem);

export default function CustomizedMenus() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <div id="tvop-menu-logo"
                onClick={handleClick}
            >
                <div id="tvop-menu-button">
                    <MenuIcon />
                </div>
            </div>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem>
                    <NavLink tag="Link" onClick={handleClose} tag={Link} className="text-dark" to="/">
                        <ListItemIcon style={{ marginLeft: '5px' }}>
                            <FontAwesomeIcon icon={faMapMarkedAlt} />
                        </ListItemIcon>
                            Kort</NavLink>
                </StyledMenuItem>
                <StyledMenuItem>
                    <NavLink onClick={handleClose} tag={Link} className="text-dark" to="/godkendte-steder">
                        <ListItemIcon style={{ marginLeft: '5px' }}>
                            <FontAwesomeIcon icon={faThumbsUp} />
                        </ListItemIcon>
                        Godkendte steder</NavLink>
                </StyledMenuItem>
                {/*<StyledMenuItem>
                    <NavLink onClick={handleClose} tag={Link} className="text-dark" to="/Steder">
                        <ListItemIcon style={{ marginLeft: '5px' }}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </ListItemIcon>
                        Steder</NavLink>
                </StyledMenuItem> */}
                <StyledMenuItem>
                    <NavLink onClick={handleClose} tag={Link} className="text-dark" to="/vis/ejer-af-psykiatrisk-servicehund">
                        <ListItemIcon style={{ marginLeft: '5px' }}>
                            <FontAwesomeIcon icon={faDog} />
                        </ListItemIcon>
                        Servicehundeejer</NavLink>
                </StyledMenuItem>
                <StyledMenuItem>
                    <NavLink onClick={handleClose} tag={Link} className="text-dark" to="/vis/Ekvipagen">
                        <ListItemIcon style={{ marginLeft: '5px' }}>
                            <FontAwesomeIcon icon={faPaw} />
                        </ListItemIcon>Ekvipagen</NavLink>
                </StyledMenuItem>
                <StyledMenuItem>
                    <NavLink onClick={handleClose} tag={Link} className="text-dark" to="/vis/kommunal-bevilling">
                        <ListItemIcon style={{ marginLeft: '5px' }}>
                            <FontAwesomeIcon icon={faLandmark} />
                        </ListItemIcon>Kommunale bevillinger</NavLink>
                </StyledMenuItem>
                <StyledMenuItem>
                    <NavLink onClick={handleClose} tag={Link} className="text-dark" to="/vis/om-os">
                        <ListItemIcon style={{ marginLeft: '5px' }}>
                            <FontAwesomeIcon icon={faAddressCard} />
                        </ListItemIcon>
                       Om os</NavLink>
                </StyledMenuItem>
                <StyledMenuItem>
                    <NavLink onClick={handleClose} tag={Link} className="text-dark" to="/vis/Jura">
                        <ListItemIcon style={{ marginLeft: '5px' }}>
                            <FontAwesomeIcon icon={faGavel} />
                        </ListItemIcon>Vilkår</NavLink>
                </StyledMenuItem>
                <StyledMenuItem>
                    <NavLink onClick={handleClose} tag={Link} className="text-dark" to="/vis/data-sikkerhed">
                        <ListItemIcon style={{ marginLeft: '5px' }}>
                            <FontAwesomeIcon icon={faLock} />
                        </ListItemIcon>Datasikkerhed og Privatlivspolitik</NavLink>
                </StyledMenuItem>
                <StyledMenuItem>
                    <LoginMenu />
                </StyledMenuItem>

            </StyledMenu>
        </div >
    );
}
