import React, { Component } from 'react';
import MenuLogo from '../Icons/the-voice-of-pets-logo.svg'

export class Loader extends Component {
    static displayName = Loader.name;
    constructor(props) {
        super(props);
        this.state = {
            text: this.props.title,
            hidden: this.props.hidden 
        };
    }
    render() {
        return (
            <div className="text-center" style={{ height: '100%',  marginTop: '25%' }}>
                <div style={{ height: '100px', width: '100px', color: '#68529a' }} className="spinner-border"></div>
                <h5><em>{this.state.text}</em></h5 >
            </div>);
    }

}