import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import cookie from 'react-cookie'
import { Loader } from '../Loader/Loader';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
//import { Location } from '../Location/Locations';
export class Page extends Component {
    static displayName = Page.name;

    constructor(props) {
        super(props);
        this.state = {
            freindlyUrl: props.match.params.path,
            page: {},
            loading: false,
            loggedIn: false,
        };

        this.onEditButtonClick.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        // check current props and nextPros of dealLoaded flag. If dealLoaded was false, and is true, which means the data is fetched, then we should reset isFlushed


       // if (!this.props.loading && nextProps.loading) {

        this.setState({
                loading: true
            });

            this.populatePageData(nextProps.match.params.path)
        //}

        

    }

    componentDidMount() {

        //Check to se if user is loggedin
        const isLoggedIn = cookie.load('idsrv.session') ? true : false;
        this.setState({ isLoggedIn: isLoggedIn });
        this.populatePageData(this.state.freindlyUrl);
    }

    renderPage = (page) => {
        return (
            <Row style={{ margin: '25px -10px', }}>

                <img src={`data:image/jpeg;base64,${page.primaryImage}`} rounded="true" responsive="true" style={{ width: '100%' }} alt={page.title} />

                <h5 style={{ marginTop: '15px' }}>{page.title}</h5>

                <div dangerouslySetInnerHTML={{ __html: page.content }}></div>

            </Row>
        );
    }

    onEditButtonClick = (event) => {
        //history.push('/my-new-location')
        //let history = useHistory();
        this.props.history.push("/EditSide/" + this.state.freindlyUrl);
    }

    render() {
        let contents = this.state.loading
            ? <Loader />
            : this.renderPage(this.state.page);

        return (
            <Row className="page">
                <Col lg="2" className="">
                    <Fab
                        className="float-rigth "
                        style={{ zIndex: '99999', position: 'fixed', bottom: '10px', right: '15px', marginRight: '10px', display: this.state.isLoggedIn ? 'block' : 'none' }}
                        color="primary"
                        size="small"
                        id="search"
                        onClick={this.onEditButtonClick}
                        title={"Rediger siden: '" + this.state.page.title + "'"}>
                        <EditIcon />
                    </Fab>
                </Col>
                <Col lg="8" xs="12">
                    {contents}

                </Col>
                <Col lg="2" className=".d-sm-none"></Col>
            </Row>
        );
    }

    async populatePageData(page) {
        const response = await fetch('/api/Page/GetByFriendlyUrl/' + page);
        const data = await response.json();
        this.setState({ page: data, loading: false });
        document.title = data.title + " · servicehunde-steder.dk";
    }
}
