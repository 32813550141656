import React, { Component } from 'react';
import './Card.css';
import { Loader } from '../Loader/Loader';
import MaterialTable from 'material-table';
import { Helmet } from "react-helmet";
import { BrandDetail } from './BrandDetail';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import Fab from '@material-ui/core/Fab';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HomeIcon from '@material-ui/icons/Home';
export class BrandsOverview extends Component {

    static displayName = BrandsOverview.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            brands: [],
            options: {
                exportButton: false,
                filtering: false,
                pageSize: 10,
                search: false,
                padding: 'dense',
                headerStyle: {
                    backgroundColor: '#68529a',
                    color: '#ffffff',
                }
            },
            columns: [
                {
                    title: 'Logo',
                    field: 'logoBlob',
                    render: rowData => this.handleLogo(rowData),

                }, { title: 'Navn', field: 'brandName' },
                { title: 'Navn 2', field: 'contactName' },
                { title: 'Adresse', field: 'address1' },
                { title: 'Postnr.', field: 'zip' },
                { title: 'By', field: 'city' },
                { title: 'Telefon', field: 'phone', },
                {
                    title: 'Email',
                    field: 'email',
                    render: rowData => <a href={'mailto:' + rowData.email}  >{rowData.email}</a>
                },
                { title: 'Titel', field: 'header' },

            ],

        }
    }
    //Shows Logo / homeicon on Brands listview
    handleLogo = (rowData) => {
        return (
            <div className="text-center">
            {rowData.showLogo
                ?
                <a href={rowData.webSite} target="_blank" title={"Gå til " + rowData.brandName + "s hjemmeside"} ><img alt={rowData.brandName} src={`data:image/jpeg;base64,${rowData.logoBlob}`} style={{ maxWidth: '50px' }} /></a>
                :
                <a href={rowData.webSite} target="_blank" title={"Vis " + rowData.brandName + " · " + rowData.address1 + " · " + rowData.zip + " " + rowData.city + "s hjemmeside"} ><HomeIcon style={{ color: '68529a' }} /> </a>
            }
            </div>
        )
    }

    onIconButtonClick = (event) => {
        this.setState({ options: { ...this.state.options, [event.currentTarget.id]: !this.state.options[event.currentTarget.id] } });

    }

    onChangeRowsPerPage = (event) => {
        this.setState({ options: { ...this.state.options, pageSize: event } });
    }

    componentDidMount() {
        //try {
        //    const acceptTableOptions = window.localStorage.getItem('BrandTableOptions');
        //    this.setState({ options: { ...JSON.parse(acceptTableOptions) } });
        //} catch (e) { }

        this.populateBrand();
    }

    //componentDidUpdate() {
    //    window.localStorage.setItem('BrandTableOptions', JSON.stringify(this.state.options));
    //}
    renderBrandsTable = (brands) => {

        return (
            <>
                <MaterialTable
                    stickyHeader
                    title="Godkendte steder"

                    columns={this.state.columns}
                    data={brands}
                    onChangeRowsPerPage={this.onChangeRowsPerPage.bind(this)}
                    options={{
                        ...this.state.options,
                        pageSize: 10,
                        padding: "dense",
                        maxBodyHeight: 'calc(100vh - 220px)',
                        filtering: false,
                        search: this.state.options.search,
                    }}

                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} af {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} række(r) valgt',
                            searchPlaceholder: 'Søg'
                        },
                        header: {
                            actions: 'Actions'
                        },
                        body: {
                            emptyDataSourceMessage: 'No records to display',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        }
                    }}

                    //detailPanel={[
                    //    {
                    //        icon: () => <InfoOutlinedIcon fontSize="large" style={{
                    //            /*color: '#68529a',
                    //            right: '-220px',
                    //            position: 'absolute',
                    //            top: -'35px' */
                    //        }} />,
                    //        openIcon: () => <InfoIcon fontSize="large" style={{
                    //            color: '#68529a',
                    //            right: '-220px',
                    //            position: 'absolute',
                    //            top: -'35px'
                    //        }} />,
                    //        tooltip: 'Vis godkendelse',
                    //        render: rowData => {
                    //            return (
                    //                <BrandDetail brand={rowData} />
                    //            )
                    //        },
                    //    },

                    //]}

                    parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                />

            </>
        );
    }


    render() {
        let contents = this.state.loading
            ? <>
                <Helmet>
                    <title>Godkendte steder · servicehunde-steder.dk</title>
                </Helmet>
                <Loader title="Henter mange data, vær tålmodig..." />
                </>
            : this.renderBrandsTable(this.state.brands);

        return (
            <>
                {contents}</>

        )
    }

    async populateBrand() {
        const response = await fetch('api/Brand/GetAll');
        const data = await response.json();

        this.setState({
            brands: data,
            loading: false,
        });

    }
}