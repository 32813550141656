import React from 'react';
import { InfoWindow, Marker, } from '@react-google-maps/api'
import HomeIcon from '@material-ui/icons/Home';
import Fab from '@material-ui/core/Fab';
import MapIcon from '@material-ui/icons/Map';
import DescriptionIcon from '@material-ui/icons/Description';
export class MarkerWithInfoWindow extends React.Component {

    constructor() {
        super();
        this.state = {
            isOpen: false
        }
        this.onToggleOpen = this.onToggleOpen.bind(this);
        this.onEventWindowClick = this.onEventWindowClick.bind(this)
        this.onPdfLinkClick = this.onPdfLinkClick.bind(this);
    }

    onToggleOpen() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    onEventWindowClick = (event) => {
        //let content = ReactDOMServer.renderToString("<h3>Test</h3>");
    }

    onPdfLinkClick = (name) => {
        alert("VIs " + name + "s godkendelse");
    }

    render() {
        const location = this.props.content;
        const imgUrl = this.props.icon;
        const urlToGoogleMaps = (location.geotag || location.geotag.length > 0 ? "https://www.google.com/maps/place/" + location.geotag : '')
        return (<Marker
            title={this.props.title}
            position={this.props.position}
            onClick={this.onToggleOpen}
            icon={{
                url: imgUrl,

                fillOpacity: 1.0,
                strokeWeight: 1.2,
                scale: .1
            }}
        >
            {this.state.isOpen && <InfoWindow
                onCloseClick={this.onToggleOpen}
                position={this.props.position}
                onMouseOut={this.onToggleOpen}
                pixelOffset={{ width: 25, height: 25 }}
                zIndex={-1}
                onClick={(event) => this.onEventWindowClick(event)}
            >
                <>

                    <h5 dangerouslySetInnerHTML={{ __html: location.name2 && location.name1.length > 0 ? location.name1 + '&nbsp;<small>' + location.name2 + '</small>' : location.name1 }}></h5>
                    <div>
                        {location.address1} {location.husNummer} ·
                        {location.address2 ? location.address2 + ' · ' : ''}
                        {location.zip} {location.city}<br />
                        {location.phone}
                        <hr style={{ margin: '5px  0' }} />
                        Kategori: {location.categoryInfo.categoryText}

                        <p>
                            {location.description && location.description.length > 0
                                ?
                                <><hr style={{ margin: '5px  0' }} />
                                    <div dangerouslySetInnerHTML={{ __html: location.description }}></div></>
                                :
                                ''}
                        </p>
                        <hr style={{ margin: '5px  0' }} />
                    </div>

                    <div style={{ marginTop: '10px' }}>
                        <a href={urlToGoogleMaps} style={{ float: 'left', marginRight: '10px' }} title={"Google Maps."} rel="noopener noreferrer" target="_blank">
                            <Fab color="primary" size="small" >
                                <MapIcon />
                            </Fab>
                        </a>
                        <a href={location.webSite} title={"Hjemmeside."} style={{ float: 'left', marginRight: '10px', display: location.webSite.length > 0 ? 'block' : 'none' }} rel="noopener noreferrer" target="_blank">
                            <Fab color="secondary" size="small">
                                <HomeIcon />
                            </Fab>
                        </a>
                        <a title={"Godkendelsesaftale / info fra thevoiceofpets.dk."} href={location.acceptDocument} style={{ float: 'left', marginRight: '5px', display: location.acceptDocument ? 'block' : 'none' }} rel="noopener noreferrer" target="_blank">
                            <Fab color="secondary" size="small">
                                <DescriptionIcon />
                            </Fab>
                        </a>
                    </div>
                </>
            </InfoWindow>}
        </Marker>)
    }
}