import React, { Component } from 'react';
import { Container } from 'reactstrap';
import PrimarySearchAppBar, { AppNav } from './AppNav/AppNav'
import cookie from 'react-cookie'

export class Layout extends Component {
    static displayName = Layout.name;

    componentDidMount() {
        //Check to se if user is loggedin 
        const isLoggedIn = cookie.load('idsrv.session') ? true : false;
        sessionStorage.setItem("isLoggedIn", isLoggedIn);
        const filter = sessionStorage.getItem('placeFilter') ? sessionStorage.getItem('placeFilter') : "";
    }

    UNSAFE_componentWillReceiveProps() {
        const isLoggedIn = cookie.load('idsrv.session') ? true : false;
        sessionStorage.setItem("isLoggedIn", isLoggedIn);
        const filter = sessionStorage.getItem('placeFilter') ? sessionStorage.getItem('placeFilter') : "";

    }

    render() {
        return (
            <div>

                <PrimarySearchAppBar />
                <Container style={{ marginTop: '60px', maxWidth: '100%' }}>
                    {this.props.children}
                </Container>
            </div>
        );
    }
}
