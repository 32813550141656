import React, { Component } from 'react';
import {
    GoogleMap,
    LoadScript,
    Marker,
    Autocomplete
} from '@react-google-maps/api'
import Geocode from "react-geocode";
import { AddPlaceModal } from './AddPlace/AddPlaceModal';
import { AddNewModal } from './AddPlace/AddNewModal';
import { MarkerWithInfoWindow } from './MarkerWithInfoWindow';
import { Col, Row } from 'reactstrap';
import { CategoryPicker } from '../Category/CategoryPicker';
import { Loader } from '../Loader/Loader';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import cookie from 'react-cookie'
import SearchIcon from '@material-ui/icons/Search';
import Fab from '@material-ui/core/Fab';
import './GoogleMaps.css'
const place = {
    id: 0,
    Category: 0,
    Name1: '',
    Name2: '',
    Address1: '',
    Address2: '',
    HusNummer: '',
    Zip: '',
    City: '',
    Phone: '',
    Email: '',
    Geotag: '',
    GooglePlusCode: '',
    Icon: '',
    webSite: '',
    Longitude: null,
    Latitude: null,
    Discription: '',
    Status: 0,
    Created: null,
    Updated: null,
    CreatedBy: 12,
    UpdatedBy: 12,
    Brand: 0, //default: Andet
}

export class GoogleMaps extends Component {
    static displayName = GoogleMaps.name;

    constructor(props) {
        super(props);
        this.autocomplete = null
        this.onLoad = this.onLoad.bind(this)
        this.onPlaceChanged = this.onPlaceChanged.bind(this)
        this.toggle = this.toggle.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
        this.handleAddNewSubmit = this.handleAddNewSubmit.bind(this);
        //this.handleFilter = this.handleFilter.bind(this);

        this.state = {
            collapsed: true,
            clicked: { lat: 0, lng: 0 },
            center: { lat: 56.293366, lng: 9.361026 },
            isModalOpen: false,
            loading: true,
            modalData: null,
            isInfoOpen: false,
            isCategoryDrawerOpen: false,
            place: place,
            clickedTitle: 'Din position',
            lastSearch: null,
            zoomLevel: 14,
            isAddNewModalOpen: false,
            showStreetView: false,
            showFullScreen: false,
            loggedIn: false,
            defaultMapOptions: {
                mapTypeControl: true,
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControlOptions: {
                    style: 1,
                    position: 3,
                },
            }
        };
    }

    setClickedLatLng = (e) => {
        this.setState({
            ...this.state,
            clicked: { lat: e.lat, lng: e.lng },

        })
        //TODO: Get info about location via google api
    };

    onToggleOpen = (ev) => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    handleToggleInfoWindow = () => {
        this.setState({ isInfoOpen: !this.state.isInfoOpen });

    }

    mapClicked = (event) => {

        //const { markers } = this.state;
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        Geocode.setApiKey("AIzaSyCqdFxX32ZgLSvF_kJsf8biOYrO4vZFjZk");
        Geocode.setLanguage("da");
        Geocode.setRegion("da");
        Geocode.fromLatLng(lat, lng).then(
            response => {

                const result = response.results;
                const address = result[0].formatted_address;
                console.log(address);
                this.setState({
                    ...this.state,
                    place: {
                        Longitude: lng,
                        Latitude: lat,
                        CreatedBy: 12,
                        UpdatedBy: 12,
                        Created: new Date(),
                        Updated: new Date(),
                        GooglePlusCode: result[0].plus_code ? result[0].plus_code.global_code : '',
                        GeoTag: result[0].geometry.location.lat + ', ' + result[0].geometry.location.lng,
                    },
                    clicked: { lat: lat, lng: lng, address: address },
                    isModalOpen: false,
                    modalData: result,
                })

                this.extractAddress(result[0].address_components);

            },
            error => {
                console.error(error);
            }

        );


    }

    extractAddress(data) {
        var StreetNumber = null;
        var StreetName = null;
        var City = null;
        var PostalCode = null;
        var State = null
        var Country = null;
        const addressComponents = data;

        addressComponents.forEach(addressComponent => {

            const addressType = addressComponent.types[0]

            if (addressType === 'street_number') {
                StreetNumber = addressComponent.long_name;

            }

            if (addressType === 'route') {
                StreetName = addressComponent.long_name;
            }

            if (addressType === 'sublocality_level_1') {
                City = addressComponent.long_name;
            }
            if (addressType === 'locality') {
                City = addressComponent.long_name;
            }

            if (addressType === 'administrative_area_level_1') {
                State = addressComponent.long_name
            }

            if (addressType === 'postal_code') {
                PostalCode = addressComponent.long_name
            }

            if (addressType === 'country') {
                Country = addressComponent.long_name
            }
        });

        this.setState({
            ...this.state,
            place: {
                ...this.state.place,
                HusNummer: StreetNumber,
                Address1: StreetName,
                City: City,
                Zip: PostalCode,
                State: State,
                Country: Country
            }
        })

    }

    GetGeoCode(lat, lng) {
        let retVal = null;
        Geocode.setApiKey("AIzaSyCqdFxX32ZgLSvF_kJsf8biOYrO4vZFjZk");
        Geocode.setLanguage("da");
        Geocode.setRegion("da");
        Geocode.fromLatLng(lat, lng).then(
            response => {
                const result = response.results;
                const address = result[0].formatted_address;
                this.setState({
                    ...this.state,
                    clicked: { lat: lat, lng: lng, address: address },
                    modalData: result,

                })
                retVal = result;
            },
            error => {
                console.error(error);

            });
        return retVal;
    }

    onLoad(autocomplete) {
        console.log('autocomplete: ', autocomplete)

        this.autocomplete = autocomplete
    }
    //Handle search autocomplete event 
    onPlaceChanged() {
        if (this.autocomplete !== null) {
            const thePlace = this.autocomplete.getPlace();
            if (!thePlace.geometry)
                return;

            const lat = thePlace.geometry.location.lat();
            const lng = thePlace.geometry.location.lng();

            if (thePlace.address_components.length > 1)
                this.extractAddress(thePlace.address_components);

            this.setState({
                clicked: { lat: lat, lng: lng },
                center: { lat: lat, lng: lng },
                clickedTitle: thePlace.name,
                lastSearch: thePlace,
                zoomLevel: 20,
                isAddNewModalOpen: this.state.isLoggedIn,
                Address: thePlace.name + ", " + thePlace.formatted_address,
                place: {
                    ...this.state.place,
                    Name1: thePlace.name,
                    Icon: thePlace.icon,
                    WebSite: thePlace.website,
                    Latitude: lat,
                    Longitude: lng,
                    CreatedBy: 12,
                    UpdatedBy: 12,
                    Created: new Date(),
                    Updated: new Date(),
                    GooglePlusCode: thePlace.plus_code ? thePlace.plus_code.global_code : '',
                    GeoTag: lat + ', ' + lng,
                    Phone: thePlace.international_phone_number,
                }
            });

            //Add result to place

            console.log('Place found @ ' + lat + ', ' + lng);
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
        this._searchBox.value = '';
    }

    centerPosition = () => {
        navigator.geolocation.getCurrentPosition(
            position => {
                const { latitude, longitude } = position.coords;

                this.setState({
                    center: { lat: latitude, lng: longitude, zoomLevel: 17 },

                });

                this.GetGeoCode(latitude, longitude);
            },
            () => {
            }
        );

    }

    componentDidMount(props) {
        document.title = "Kort over steder · servicehunde-steder.dk";

        navigator.geolocation.getCurrentPosition(
            position => {
                const { latitude, longitude } = position.coords;

                this.setState({
                    center: { lat: latitude, lng: longitude },

                });

                this.GetGeoCode(latitude, longitude);
            },
            () => {
                this.setState({ loading: true });
            }
        );
        //Check to se if user is loggedin 
        const isLoggedIn = cookie.load('idsrv.session') ? true : false;
        this.setState({ isLoggedIn: isLoggedIn });
        sessionStorage.setItem("isLoggedIn", true);
        const filter = sessionStorage.getItem('placeFilter') ? sessionStorage.getItem('placeFilter') : "";

        this.populateLocationDataFiltered(filter);


    }

    //Toogles add new place modal
    toggle() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }

    closeModal() {
        this.setState({ isModalOpen: false, loading: true });
        this.populateLocationDataFiltered("");
    }

    cancelModal() {

        this.setState({ isModalOpen: false });
    }

    closeAddNewModal() {
        this.setState({ isAddNewModalOpen: false, loading: true });

    }

    cancelAddNewModal() {

        this.setState({ isAddNewModalOpen: false });
    }

    handleAddNewSubmit() {

        this.setState({ isAddNewModalOpen: false, isModalOpen: true });
    }

    handleSubmitFromFilter = () => {

        const categoriesSelected = JSON.parse(localStorage.getItem("categoriesSelected"));
        if (categoriesSelected) {
            this._buildFilter(categoriesSelected);
        }
    }

    _buildFilter(catSelected) {

        const categories = JSON.parse(localStorage.getItem("categories"));
        let strFilter = "";

        for (let i = 0; i < categories.length; i++) {
            if (!catSelected['chkCat' + (i + 1)])
                strFilter += categories[i].id + ', ';
        }

        strFilter = strFilter.substring(0, (strFilter.length - 2));
        sessionStorage.setItem('placeFilter', strFilter);

        this.populateLocationDataFiltered(strFilter);

    }

    _insertButton = (parentThis, map) => {
        let controlDiv = document.createElement('div');
        controlDiv.innerHTML = "tester";
        //   this._googleMap.controls[this._googleMap.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
    }

    loadGoogleMaps(locations) {
        const libs = ["places", "drawing"];


        return (
            <>
                <Fab
                    className="tvop-position-icon"
                    color="default"
                    size="small"
                    onClick={this.centerPosition}
                    id="centerPosition"
                    title="Centrer kort">
                    <GpsFixedIcon />
                </Fab>

                <Row>
                    <Col xs="12" style={{ padding: '0' }} >
                        <CategoryPicker
                            open={this.state.isCategoryDrawerOpen}
                            data={locations}
                            isOpen={this.state.isCategoryDrawerOpen}
                            handleSubmit={() => this.handleSubmitFromFilter}
                        />

                        <LoadScript
                            googleMapsApiKey="AIzaSyDwcPfHY_AMQ6xu871WDAun-GmebsSVpVY"
                            libraries={libs} >

                            <GoogleMap
                                onGoogleApiLoaded={({ map, maps }) => {
                                    this.map = map
                                    this.maps = maps
                                    // we need this setState to force the first mapcontrol render
                                    console.log("Top_Center: " + maps.ControlPosition.TOP_CENTER);
                                    console.log("Horizontial_BAr: " + maps.ControlPosition.HORIZONTAL_BAR);
                                    this.setState({
                                        mapControlShouldRender: true,
                                    })
                                }}
                                mapContainerStyle={{ height: 'calc(100vh - 65px)', width: '100%', margin: '0', padding: '0 ' }}
                                center={this.state.center}
                                zoom={this.state.zoomLevel}
                                options={this.state.defaultMapOptions}

                            //onRightClick={evt => this.mapClicked(evt)}
                            >
                                <Autocomplete
                                    onLoad={this.onLoad}
                                    onPlaceChanged={this.onPlaceChanged}
                                >
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            placeholder="Søg"
                                            id="searchBox"
                                            ref={i => this._searchBox = i}
                                        />
                                        <div className="input-group-append" >
                                            <span className="input-group-text" ><SearchIcon /></span>
                                        </div>
                                    </div>
                                </Autocomplete>
                                {

                                }
                                {locations.map(location =>
                                    <MarkerWithInfoWindow
                                        key={location.id}
                                        title={location.name1 + " (" + location.categoryInfo.categoryText + ")"}
                                        position={{ lng: location.longitude, lat: location.latitude }}
                                        content={location}
                                        icon={"/Marker/marker_" + location.categoryInfo.id + ".png"}
                                    />
                                )}
                                {/* The Voice of Pets */}

                                {/* Clicked Marker*/}

                                <Marker
                                    key={-1}
                                    title={this.state.clicked.Address}
                                    position={{ lng: this.state.clicked.lng, lat: this.state.clicked.lat }}
                                    icon={{
                                        path: "M 115.00,49.23 C 147.32, 49.14 162.50, 90.16 138.96, 111.82 130.75, 119.37 122.71, 121.12 112.00, 121.00 96.24, 120.81 82.22, 109.42 78.68, 94.00 77.89, 90.57 77.99, 87.48 78.00, 84.00 78.08, 66.67 89.22, 53.46 106.00, 49.23 109.32, 49.00 111.67, 48.99 115.00, 49.23 Z",
                                        fillColor: "#0000ff",
                                        fillOpacity: 1.0,
                                        strokeWeight: 1,
                                        scale: .15

                                    }}

                                ></Marker>
                                { /* Child components, such as markers, info windows, etc. */}
                            </GoogleMap>
                        </LoadScript>
                    </Col>
                </Row>
            </>)
    }

    render() {
        let contents = this.state.loading
            ? <Loader title="Henter kort..." />
            : this.loadGoogleMaps(this.state.locations)
        return (
            <div>
                <AddPlaceModal
                    handleOpen={this.state.isModalOpen}
                    handleSubmit={() => this.closeModal()}
                    handleCancel={() => this.cancelModal()}
                    modalData={this.state.modalData}
                    place={this.state.place}
                />
                <AddNewModal
                    handleOpen={this.state.isAddNewModalOpen}
                    handleCancel={() => this.cancelAddNewModal()}
                    handleSubmit={() => this.handleAddNewSubmit()}
                    modalData={this.state.modalData}
                    place={this.state.place}
                    address={this.state.Address}
                />

                {contents}
            </div>);
    }


    async postData() {
        const data = this.state.data;
        this.setState({ loading: true })
        await fetch('api/Location/Create', {
            method: 'post',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            this.setState({ loading: false })

            return response.json(); //response.json() is resolving its promise. It waits for the body to load
        }).then(function (responseData) {
            //Do your logic
            this.setState({ loading: false })
            console.log(responseData);
        });


    }

    async populateLocationDataFiltered(filter) {
        this.setState({ loading: true })
        const urlToFetch = filter === "" ? '/api/Location' : '/api/Location/getByFilter/' + filter
        const response = await fetch(urlToFetch);
        const data = await response.json();

        this.setState({ locations: data, loading: false });

    }

    //async populateLocationData() {
    //    const response = await fetch('/api/Location');
    //    const data = await response.json();
    //    const strFilter = "cat.category != 1 && cat.category != 4"
    //    //libraries.filter(l => {
    //    //    return l.name.toLowerCase().match(searchString);
    //    //});
    //    const filteredData = data; //.filter((cat) => );
    //    //const filteredData = data.filter(l => {
    //    //    return l.id.(searchString);
    //    //});

    //    this.setState({ locations: filteredData, loading: false });

    //}

}
