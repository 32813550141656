import React from 'react';
import MaterialTable from 'material-table';
import { Helmet } from "react-helmet";

export class AdminSteder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            locations: [],
            columns: [
                { title: 'Titel/Navn', field: 'name1' },
                { title: 'Kontaktperson', field: 'name2' },
                { title: 'Adresse', field: 'address1' },
                { title: 'Stednavn', field: 'address2' },
                { title: 'Postnr.', field: 'zip' },
                { title: 'By', field: 'city' },
                { title: 'Telefon', field: 'phone', },
                { title: 'Status', field: 'status', lookup: { 0: 'Ikke angivet', 1: 'Oprettet', 2: 'Under behandling', 3: 'Godkendt', 4: 'betinget godkendt', 5: 'Afvist' } },
            ],

        }
    }


    componentDidMount() {
        document.title = document.title + " Steder Administration";

        this.populateLocationData();
    }

    loadLocationTable(locations) {
        return (
            <MaterialTable
                title="Steder"
                columns={this.state.columns}
                data={locations}
              
                options={{
                    exportButton: false
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} af {count}'
                    },
                    toolbar: {
                        nRowsSelected: '{0} række(r) valgt'
                    },
                    header: {
                        actions: 'Actions'
                    },
                    body: {
                        emptyDataSourceMessage: 'Ingen data',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                {
                                    const data = this.state.data;
                                    data.push(newData);
                                    this.setState({ data }, () => resolve());
                                }
                                resolve()
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                {
                                    const data = this.state.data;
                                    const index = data.indexOf(oldData);
                                    data[index] = newData;
                                    this.setState({ data }, () => resolve());
                                }
                                resolve()
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                {
                                    let data = this.state.data;
                                    const index = data.indexOf(oldData);
                                    data.splice(index, 1);
                                    this.setState({ data }, () => resolve());
                                }
                                resolve()
                            }, 1000)
                        }),
                }}
            />)
    }

    render() {
        let contents = this.state.loading
            ?
            <div className="text-center" style={{ height: '100%', marginTop: '20%' }}>
                <div style={{ height: '100px', width: '100px' }} className="spinner-border text-success"></div><h3><em>Henter steder...</em></h3>
            </div>

            : this.loadLocationTable(this.state.locations)

        return (
            <>
                <Helmet>
                    <title>Steder Administration - servicehunde-steder.dk</title>
                </Helmet>

                {contents}</>
        )
    }


    async populateLocationData() {
        const response = await fetch('/api/Location');
        const data = await response.json();
        this.setState({ locations: data, loading: false });
    }
}