import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { GoogleMaps } from './components/Map/GoogleMaps';
import { Page } from './components/Page/Page';
import { PageEdit } from './components/Page/PageEdit';
import { Steder } from './components/Steder/Steder';
import { AdminSteder } from './components/Steder/AdminSteder';
import { CategoryAdmin } from './components/Category/CategoryAdmin';
import { Brand } from './components/Brand/Brand';
import { BrandsOverview } from './components/Brand/BrandsOverview';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { FormTest } from './components/AddPlace/FormTest';
import { NotFound } from './components/NotFound/NotFound';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Switch>
                    <Route exact path='/' component={GoogleMaps} />
                    <Route exact path='/kort' component={GoogleMaps} />
                    <Route path='/vis/:path' component={Page} />
                    <AuthorizeRoute path='/kategoriAdmin' component={CategoryAdmin} />
                    <Route path='/steder' component={Steder} />
                    <Route path='/brands' component={Brand} />
                    <Route path='/formtest' component={FormTest} />
                    <Route path='/godkendte-steder' component={BrandsOverview} />
                    <AuthorizeRoute path='/stederAdmin' component={AdminSteder} />
                    <AuthorizeRoute path='/EditSide/:path' component={PageEdit} />
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    <Route component={NotFound} />
                </Switch>
            </Layout>
        );
    }
}
