import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Icon from '@material-ui/core/Icon';
//import green from '@material-ui/core/colors/green';
//import SaveIcon from '@material-ui/icons/Save';

export class AddNewModal extends Component {
    static displayName = AddNewModal.name;
    constructor(props) {
        super(props);
        //        this.textFieldOnChange = this.textFieldOnChange.bind(this);
        this.state = {
            isOpen: this.props.handleOpen,
            modalData: this.props.modalData,
            place: null,
        };
    }

    handleClose = (ev) => {
        this.setState({
            ...this.state,
            place: null,
        })
        this.props.handleCancel();
    }

    

    render() {
        return (
            <Dialog
                open={this.props.handleOpen}
                onClose={this.handleClose}
                maxWidth="sm"
                disableBackdropClick={true}
                fullWidth={true}
                modaldata={this.state.modalData}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Opret:<br />{this.props.address ? this.props.address : ''}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vil du oprette<br /><strong>{this.props.address}</strong> som nyt sted i servicehunde-steder.dk?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this)} color="secondary">
                        Nej</Button>
                    <Button
                        onClick={this.props.handleSubmit}
                        color="primary">
                        Ja</Button>
                </DialogActions>
            </Dialog>)
    }

}