import React from 'react';
import { Loader } from '../Loader/Loader';
import MaterialTable from 'material-table';
import { Helmet } from "react-helmet";
//import { Location } from '../Location/Locations';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import Fab from '@material-ui/core/Fab';
export class Steder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            locations: [],
            options: {
                exportButton: false,
                filtering: true,
                pageSize: 5,
                search: true,
                padding: 'dense',
            },
            columns: [
                { title: 'Titel/Navn', field: 'name1' },
                { title: 'Kontaktperson', field: 'name2' },
                { title: 'Adresse', field: 'address1' },
                { title: 'Husnr.', field: 'husNummer', filtering: false, },
                { title: 'Stednavn', field: 'address2' },
                { title: 'Postnr.', field: 'zip' },
                { title: 'By', field: 'city' },
                { title: 'Telefon', field: 'phone', },
                { title: 'Kategori', field: 'category', lookup: { 1: 'Indkøb', 2: 'Sundhed', 3: 'Off. myndighed', 4: 'Kultur', 5: 'Institution', 6: 'Restaurant/Spisested', 7: 'The Voice of Pets', 9: 'Andet', 10: 'Godkendt træner' } },
                { title: 'Status', field: 'status', lookup: { 0: 'Ikke angivet', 1: 'Oprettet', 2: 'Under behandling', 3: 'Godkendt', 4: 'betinget godkendt', 5: 'Afvist' } },
            ],

        }
    }



    componentDidMount() {
        try {
            const materialTableOptions = window.localStorage.getItem('MaterialTableOptions');
            this.setState({ options: { ...JSON.parse(materialTableOptions) }});
        } catch (e) { }

        this.populateLocationData();
    }

    componentDidUpdate() {
        window.localStorage.setItem('MaterialTableOptions', JSON.stringify(this.state.options));
    }

    onIconButtonClick = (event) => {
        this.setState({ options: { ...this.state.options, [event.currentTarget.id]: !this.state.options[event.currentTarget.id] } });

    }

    onChangeRowsPerPage = (event) => {
        this.setState({ options: { ...this.state.options, pageSize: event } });
    }

    loadLocationTable(locations) {
        return (
            <>
                <p><Fab className="float-rigth" style={{ marginRight: '10px' }}  color="primary" size="small" id="search" onClick={this.onIconButtonClick.bind(this)} title="Tænd/Sluk søg"><SearchIcon /></Fab>
                    <Fab className="float-rigth" id="filtering" focusVisible={false} color="secondary" size="small" onClick={this.onIconButtonClick.bind(this)} title="Tænd/Sluk filter"><FilterListIcon /></Fab></p>
            <MaterialTable
                title="Steder"
                columns={this.state.columns}
                data={locations}
                onChangeRowsPerPage={this.onChangeRowsPerPage.bind(this)}

                options={{
                    ...this.state.options,
                    padding: "dense",
                }}
                />
                
                </>)
    }


    render() {
        let contents = this.state.loading
            ?
            <Loader title="Henter steder..." />

            : this.loadLocationTable(this.state.locations)

        return (
            <>
                <Helmet>
                    <title>Steder - servicehunde-steder.dk</title>
                </Helmet>

                {contents}</>
        )
    }


    async populateLocationData() {
        const response = await fetch('/api/Location');
        const data = await response.json();
        this.setState({ locations: data, loading: false });
    }
}