import React from "react";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";


const place = {
    id: 0,
    Category: 0,
    Name1: 'Anders And',
    Name2: 'Hidsigprop',
    Address1: 'Paradisvej 11',
    Address2: 'Søndermosen',
    Zip: 'AB 9999',
    City: 'Andeby',
    Phone: '99 88 77 66',
    Email: 'a.and@andeby.duck',
    Geotag: '',
    GooglePlusCode: '',
    HusNummer: '',
    WebSite: '',
    Longitude: null,
    Latitude: null,
    Description: ' ',
    Status: 0,
    Created: null,
    Updated: null,
    CreatedBy: 0,
    UpdatedBy: 0,
}



const level = [
    {
        value: "Easy",
        label: "Easy"
    },
    {
        value: "Mid",
        label: "Mid"
    },
    {
        value: "Difficult",
        label: "Difficult"
    }
];
const category = [
    {
        value: "Science",
        label: "Science"
    },
    {
        value: "Islam",
        label: "Islam"
    },
    {
        value: "Other",
        label: "Other"
    }
];
export class FormTest extends React.Component {
    state = {
        question: "",
        level: "",
        category: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        place: { ...place },
    };

    handleChange = prop => event => {
        this.setState({ place: { ...this.state.place, [prop]: event.target.value } });
    };

    render() {
        console.log("state", this.state);
        return (
            <div className="animated slideInUpTiny animation-duration-3">
                <div className="m-5 col-8">
                    <div className="row">
                        <TextField
                            id="Name1"
                            label="Navn 1"
                            value={this.state.place.Name1}
                            onChange={this.handleChange("Name1")}
                            margin="normal"
                            
                        />

                        <TextField
                            id="Name2"
                            label="Navn 2"
                            value={this.state.place.Name2}
                            onChange={this.handleChange("Name2")}
                            margin="normal"
                            
                        />
                        <TextField
                            id="Address1"
                            label="Adresse 1"
                            value={this.state.place.Address1}
                            onChange={this.handleChange("Address1")}
                            margin="normal"
                        />
                        <TextField
                            id="Address2"
                            label="Adresse 2"
                            value={this.state.place.Address2}
                            onChange={this.handleChange("Address2")}
                            margin="normal"
                        />
                        <TextField
                            id="Zip"
                            label="Post nr."
                            value={this.state.place.Zip}
                            onChange={this.handleChange("Zip")}
                            margin="normal"
                        />
                        <TextField
                            id="City"
                            label="By"
                            value={this.state.place.City}
                            onChange={this.handleChange("City")}
                            margin="normal"
                        />

                    </div>
                </div>
                <div className="col-4">
                    <pre>{JSON.stringify(this.state.place   , 0, 2)}</pre>
                </div>
            </div>
        );
    }
}

