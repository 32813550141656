import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Col, Row } from 'reactstrap';
import './CategoryPicker.css'
import Fab from '@material-ui/core/Fab';
import BallotIcon from '@material-ui/icons/Ballot';
export class CategoryPicker extends Component {

    static displayName = CategoryPicker.name;

    constructor(props) {
        super(props);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.onCategoryCheckboxChecked = this.onCategoryCheckboxChecked.bind(this);
        //        this.handleFilter = this.handleFilter.bind(this);
        this.state = {
            loading: true,
            categories: this.props.data,
            openDrawer: this.props.isOpen,
            anchor: "right"

        };
    }

    toggleDrawer = () => (_event) => {

        if (this.state.openDrawer)
            localStorage.setItem("categoriesSelected", JSON.stringify(this.state.categorySelected));

        this.setState({ ...this.state, openDrawer: !this.state.openDrawer });
    };


    componentDidMount(props) {
        this.populateCategoryData();
    }

    onCategoryCheckboxChecked = (event) => {
        this.setState({
            ...this.state,
            categorySelected: {
                ...this.state.categorySelected,
                [event.target.value]: event.target.checked
            }

        });

        this.setState({ value: event.target.value }, function () {
            console.log(this.state.categoriesSelected);
            localStorage.setItem("categoriesSelected", JSON.stringify(this.state.categorySelected));
        });
    }


    onDrawerOpen() {

        this.setState({ ...this.state, openDrawer: true });
    }


    //handleFilter() {

    //    //this.setState({ ...this.state, openDrawer: !this.state.openDrawer });
    //    localStorage.setItem("categoriesSelected", JSON.stringify(this.state.categorySelected));
    //    this.props.handleFilter();
    //};

    //handleSubmit() {    
    //    //this.saveSettings();
    //    //this.setState({ open: false });
    //    localStorage.setItem("categoriesSelected", JSON.stringify(this.state.categorySelected));
    //    this.props.handleSubmit();
    //}

    loadCategoryList(categories) {
        return (
            <>
                <React.Fragment>
                    <SwipeableDrawer anchor={this.state.anchor}
                        open={this.state.openDrawer}
                        onOpen={this.onDrawerOpen.bind(this)}
                        onClose={this.toggleDrawer()}>
                        <h6 style={{ padding: '10px' }}>Filterer Kategorier</h6>
                        {categories.map(category =>
                            <table key={category.id} >
                                <tbody style={{ display: category.itemCount > 0 ? 'block' : 'none' }}>
                                    <tr><td>

                                        <img src={"/Marker/icon_category_" + category.id + ".png"} width="25" alt={category.categoryText} style={{ marginTop: '-10px', marginLeft: '30px', marginRight: '20px' }} />

                                    </td><td>

                                            <div >

                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            color="default"
                                                            name={category.CategoryText}
                                                            checked={this.state.categorySelected['chkCat' + category.id]}
                                                            onClick={this.onCategoryCheckboxChecked}
                                                            value={'chkCat' + category.id}
                                                        />
                                                    }
                                                    label={category.categoryText + " (" + category.itemCount + ")"}

                                                />
                                                {}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                        )}
                        <Row>
                            <Col lg="6" className="text-center">
                                <Button color="secondary" onClick={this.toggleDrawer()} >
                                    Annuller
                                </Button>
                            </Col>
                            <Col lg="6" className="text-center">
                                <Button color="primary" onClick={this.props.handleSubmit()} >
                                    Vis valgte
                                </Button>
                            </Col>
                        </Row>

                    </SwipeableDrawer>


                </React.Fragment>
            </>
        );

    }



    render() {
        let contents = this.state.loading
            ? <></>
            : this.loadCategoryList(this.state.categories)
        return (<>
            <IconButton
                className="TVOP-category-icon"
                color="default"
                size="small"
                onClick={this.toggleDrawer()}
                id="showCategorySelection"
                title="Vælg kategori(er).">
                <BallotIcon style={{fontSize:'2.5rem'}}  />
            </IconButton>
              {contents}
        </>
        );

    }


    async populateCategoryData() {
        const response = await fetch('/api/Categori');
        const data = await response.json();
        const catSelected = JSON.parse(localStorage.getItem('categoriesSelected'));

        if (!catSelected) {
            //{ "chkCat1": true, "chkCat2": false, "chkCat3": true, "chkCat4": false, "chkCat5": true, "chkCat6": true, "chkCat8": true, "chkCat7": true }
            for (var c of data) {
                this.setState({ categorySelected: { ...this.state.categorySelected, ['chkCat' + c.id]: c.isChecked } })
            }

        } else {

            this.setState({ categorySelected: catSelected })
        }


        localStorage.setItem('categories', JSON.stringify(data));

        this.setState({ categories: data, loading: false });

    }
}